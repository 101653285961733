var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Loader',{attrs:{"isLoading":_vm.isLoading}}):_c('div',[(_vm.redirection)?_c('TeamOffRedirection'):_vm._e(),_c('MainHeader',{attrs:{"collapsed":_vm.collapsed},on:{"toggleCollapse":_vm.toggleCollapse}}),_c('a-layout',{staticStyle:{"background-color":"#fff"},style:({ height: 'calc(100vh - 80px) !important', overflow: 'hidden' }),attrs:{"id":"layout"}},[_c('a-layout-sider',{staticClass:"bg-white shadow-border sidebar-trigger",style:({
            height: 'calc(100vh - 80px)',
            overflowY: 'auto',
            overflowX: 'hidden',
          }),attrs:{"trigger":null,"collapsible":"","collapsedWidth":28,"width":"250","breakpoint":"sm"},on:{"breakpoint":_vm.responsiveToggle},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('Sidebar',{attrs:{"collapsed":_vm.collapsed,"searchBtnClicked":_vm.searchBtnClicked},on:{"collapseSideBar":function($event){_vm.collapsed = !_vm.collapsed}},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('SimpleSearch',{ref:"simpleSearch",attrs:{"user":_vm.loggedUser},on:{"switchComponent":_vm.switchComponent,"searchBtnClicked":_vm.closeSearchSidebar},scopedSlots:_vm._u([{key:"button",fn:function(ref){
          var clickBtn = ref.clickBtn;
return [_c('ButtonComponent',{ref:"btnsearch",attrs:{"iconHeight":"20px","block":true,"responsive":false,"title":"Search","icon":require('@/assets/icon/search-love-primary.svg'),"customEvent":"handleSearch"},on:{"onClickButton":clickBtn}})]}},{key:"default",fn:function(ref){
          var search = ref.search;
return [_c('button',{directives:[{name:"begin-search",rawName:"v-begin-search"}],ref:"suggestion",staticClass:"btn-adv-search",on:{"click":search}},[_vm._v(" Reset Search ")])]}}])})]},proxy:true}])})],1),_c('a-layout',{attrs:{"id":"bbx"}},[_c('a-layout-content',[_c('div',{staticClass:"main-content-wrapper",attrs:{"id":"top"}},[_c('div',{staticClass:"main-content-1"},[_c(_vm.currentTabComponent,{tag:"component",attrs:{"role":_vm.teamRole},on:{"switchComponent":_vm.switchComponent,"navigateProfile":_vm.navigateProfile,"socketNotification":_vm.socketNotification,"searchBtnClicked":_vm.toggleSearchBtnClick}})],1),_c('div',{staticClass:"main-content-2"},[_c(_vm.rightSideComponentName,{tag:"component"})],1)])])],1)],1)],1),_c('Loader',{attrs:{"isLoading":_vm.isFetching}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }