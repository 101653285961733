<template>
    <v-card
            style="height: calc(100vh - 110px); overflow-y: auto; position: fixed; right: 10px;"
            class="mx-auto"
            width="317"
        >
        <div style="height: 100%">
            <!-- <img class="mx-auto" style="height: 100%; width:100%; display: block" src="https://dummyimage.com/340x1:3/" alt=""> -->
            <div class="mx-auto d-flex justify-content-center align-items-center" style="height: 100%; width: 100%; background: #cccccc">
                <div>

                    <h4 class="text--disabled">Advertising area</h4>
                    <h4 class="text--disabled text-center">317 X 800</h4>

                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    name:'AddComponent'
}
</script>
